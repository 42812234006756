<template>
  <div>
    <!--<h5 class="font-weight-light pb-3">Gestión de usuariosss</h5>-->
    <v-card class="mx-auto" max-width="344">
      <v-img width="344" :src="imageSrc">
        <v-app-bar
          flat
          color="rgba(255, 255, 255, 0.2)"
          style="border: 0px; height: 70px"
        >
          <v-spacer></v-spacer>

          <v-spacer></v-spacer>

          <v-avatar color="rgba(0, 0, 0, 0.3)">
            <v-icon class="white--text" dark @click="$refs.file.click()"
              >mdi-pencil</v-icon
            >
          </v-avatar>
        </v-app-bar>
        <input
          @change="uploadImage"
          type="file"
          ref="file"
          hidden
          name="photo"
          accept="image/*"
        />
      </v-img>

      <v-card-title> Antecedentes Personales </v-card-title>
      <v-list class="transparent">
        <!--<v-list-item class="m-0">
          <v-list-item-icon>
            <v-icon color="indigo">mdi-account-circle</v-icon>
          </v-list-item-icon>

          <v-list-item-subtitle> Tipo Usuario </v-list-item-subtitle>
          <v-list-item-title style="align-text: right">algo mas</v-list-item-title>
        </v-list-item>-->
        <v-list-item>
          <v-text-field
                      label="Nombre"
                      v-model="datosFormulario.nombre"
                      outlined
                      
                      hide-details
                      
                      required
                    ></v-text-field>
        </v-list-item>
      <v-card-title class="mb-0 pb-0"> Antecedentes de su cuenta </v-card-title>
      <v-subheader>Solo si requiere cambiar clave</v-subheader>
      <v-list-item>
          <v-text-field
            id="password"
            class="font-weight-light"
            label="Clave"
            outlined
            name="password"
            prepend-inner-icon="mdi-lock"
            color="primary"
            :append-icon="value ? 'visibility' : 'visibility_off'"
            @click:append="() => (value = !value)"
            :type="value ? 'password' : 'text'"
            v-model="datosFormulario.pass"
          ></v-text-field>
        </v-list-item>
        <v-list-item>
          <v-text-field
            id="password2"
            class="font-weight-light"
            label="Repita Clave"
            outlined
            name="password2"
            prepend-inner-icon="mdi-lock"
            color="primary"
            :append-icon="value ? 'visibility' : 'visibility_off'"
            @click:append="() => (value = !value)"
            :type="value ? 'password' : 'text'"
            v-model="datosFormulario.pass2"
          ></v-text-field>
        </v-list-item>
      </v-list>
      <v-btn
              style="width: 100%; border-top-left-radius: 0px;border-top-right-radius: 0px;"
              
              color="verde white--text"
              dark
              @click="editData"
              >Grabar cambios</v-btn
            >
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

//Definimos la clase de los elementos
class datosReporte {
  constructor(datos = {}) {
    this.nombre = datos.nombre;
    this.email = datos.email;
    this.foto = datos.foto;
    this.pass = datos.pass;
    this.pass2 = datos.pass2;
  }
}

export default {
  data() {
    return {
      loading: true,
      imageSrc: null,
      datosFormulario: new datosReporte(new datosReporte()),
      value: String,
    };
  },

  computed: {
    ...mapState(["token", "notificationSystem", "usuarioDB"]),
  },
  methods: {
    getData() {
      let config = {
        headers: {
          token: this.token,
        },
      };
      axios.get("/users/perfil", config).then((response) => {
        this.datosFormulario = response.data;
        this.loading = false;
        if (
          this.datosFormulario.foto &&
          this.datosFormulario.foto != "undefined"
        ) {
          this.imageSrc =
            process.env.VUE_APP_BASE_URL +
            "/users/verFoto/" +
            this.datosFormulario.foto;
        } else {
          this.imageSrc = require("@/assets/imagenes/perfilDefault.png");
        }
      });
    },
    uploadImage(e) {
      const imagenSeleccionada = e.target.files[0];
      this.createBase64(imagenSeleccionada);
    },
    createBase64(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imageSrc = e.target.result;
        this.datosFormulario.file = this.$refs.file.files[0];
      };
      reader.readAsDataURL(fileObject);
    },
    async editData() {
      this.loading = true;

      if (this.datosFormulario.pass == this.datosFormulario.pass2) {
        let config = {
          headers: {
            token: this.token,
            "Content-Type": "multipart/form-data;",
          },
        };

        const formData = new FormData();
        formData.append("file", this.$refs.file.files[0]);
        formData.append("nombre", this.datosFormulario.nombre);
        formData.append("pass", this.datosFormulario.pass);
        formData.append("foto", this.datosFormulario.foto);

        await axios
          .put("/users/perfil/", formData, config)
          .then((response) => {
            this.$router.push({
              name: "usuarios",
            });
            this.$toast.success(
              "Grabado con exito!",
              "",
              this.notificationSystem.options.error
            );
          })
          .catch((e) => {
            this.loading = false;
            this.$toast.error(
              "Ocurrio un error de sistema, vuelva a intentarlo",
              "Error",
              this.notificationSystem.options.error
            );
          });
      } else {
        this.loading = false;
        this.$toast.error(
          "Las claves no coinciden, rellene y vuelva a intentarlo",
          "Error",
          this.notificationSystem.options.error
        );
      }
    },
  },
  created() {
    this.getData();
  },
};
</script>
<style>
#cardborde {
  border-top: 5px solid #e8eaf6 !important;
}
.v-toolbar__content {
  border: 0px !important;
  height: 70px;
}
.v-label--active {
  color: #041b4d !important;
  font-weight: bold;
}
</style>
